<template>
  <div class="">
    <top-nav></top-nav>
    <div class="arcanite">
    <!-- <div class="homepage"> -->
      <div class="part1BookDemo">
        <div class="part1Middle">
          <div class="leftContent">
            <div class="part1title">
              Arcanite
            </div>
            <div class="part1content">
              Arcanite's frontend app is the must-have automation tool for on-the-go agents and developers. Stay connected, updated and on track by selling off-the-plan properties the Arcanite way!
            <!-- Arcanite is the ultimate frontend application for on-the-go project marketers. It allows you to keep all parties connected, all projects updated, and all processes automated. Discover how thousands of developers and agents are saving valuable time and money by selling off-the-plan properties the Arcanite way! -->
            </div>
            <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
              return false;">
            <button class="part1Button"><div class="bottomText">Book A Demo</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
            </a>
          
            <!-- <div class="leftContent"> -->
              <!-- <recognised></recognised> -->
              <!-- <trusted></trusted> -->
            <!-- </div> -->
          </div>
          <div class="rightContent">
            <img src="./../../assets/imgs/pc/arcanite/p1.png" class="p1"/>
          </div>
        </div>
      </div>
      <div class="part2RecoginizedIcons">
        <div class="part2-topcontent">
          <trusted></trusted>
        </div>
        <div class="part2-midcontent">
          <featuredIn></featuredIn>
        </div>
        <div class="part2-bottomcontent">
            <recognised></recognised>
        </div>
      </div>
      <div class="part3PicsText" style="height:700px">
        <img src="./../../assets/imgs/pc/arcanite/p2.png" class="background" />
        <div class="leftContent" style="marginTop:80px">
          <img src="./../../assets/imgs/pc/arcanite/p21.png" class="pic2" />
        </div>
        <div class="rightContent" >
          <div class="p1" style="top:220px">
            <img src="./../../assets/imgs/pc/arcanite/minicons1.png" class="pic" />
            <p>View your projects your way</p>
            <div class="content">
                View all your current projects with quick links to availability, specifications, sharable information, marketing material and VR property rendering. Customise how you view your home screen for efficient property searches.
            </div>
          </div>
        </div>
      </div>
      <div class="part3PicsText" style="height:700px">
        <div class="rightContent">
          <div class="p1">
            <img src="./../../assets/imgs/pc/arcanite/minicons2.png" class="pic" />
            <p>All of your contacts in one place</p>
            <div class="content">
            Instantly add and organise your contact list. Sort contacts into a range of varying categories and filter based on sales stage, client source and more.
            </div>
            <div class="minipeople">
              <img src="./../../assets/imgs/pc/arcanite/minip1.png" class="minip" />
              <img src="./../../assets/imgs/pc/arcanite/minip2.png" class="minip" />
              <img src="./../../assets/imgs/pc/arcanite/minip3.png" class="minip" />
              <img src="./../../assets/imgs/pc/arcanite/minip4.png" class="minip" />
            </div>
          </div>
        </div>
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcanite/p3.png" class="pic3" />
        </div>
      </div>
      <div class="part3PicsText" >
        <div class="leftContent">
          <img src="./../../assets/imgs/pc/arcanite/p4.png" class="pic4" />
        </div>
        <div class="rightContent">
          <div class="p1" style="top:20%">
            <img src="./../../assets/imgs/pc/arcanite/minicons3.png" class="pic" />
            <p>Track sales more efficiently than ever</p>
            <div class="content">
              Track progress on all sales and filter searches based on current sales status. Sort by price and date, for efficient tracking and follow-ups.
            </div>
            <div class="line"></div>
            <div class="content">
              <div class="dog"></div><div class="text">Filter and sort to suit your preferences</div><br>
              <div class="dog"></div><div class="text">Oversee agent requests</div><br>
              <div class="dog"></div><div class="text">View sales statistics for ongoing R&D</div><br>
            </div>
          </div>
        </div>
      </div>
      <div class="part3PicsText" style="height:400px">
        <div class="rightContent" style="height:0">
          <div class="p1">
            <img src="./../../assets/imgs/pc/arcanite/minicons4.png" class="pic" />
            <p>Never miss an update</p>
            <div class="content">
           Organise and add tasks to share amongst your team. Categorise each task for quick identification and use the inbuilt calendar to ensure all responsibilities are kept on track and send out reminder notifications.
            </div>
          </div>
        </div>
        <div class="rightContent" style="height:0;margin-left:250px">
          <div class="p1">
            <img src="./../../assets/imgs/pc/arcanite/minicons5.png" class="pic" />
            <p>All access to everything that matters</p>
            <div class="content">
                Access saved lists, reports, invoices, partner contacts, training material, company information and much more. All you need to know to make a sale is in the palm of your hand.
            </div>
          </div>
        </div>
      </div>
      <div class="part4Feature">
        <img src="./../../assets/imgs/pc/arcanite/p5.png" class="pic4" />
        <h2>
            Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes.
        </h2>
        <a onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
          <button class="part4Button"><div class="bottomText">Book A Demo</div><img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12"></button>
        </a>
      </div>
      <div class="part5Comments">
        <other-product></other-product>
      </div>
    <!-- </div> -->
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data() {
      return{
      }
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  },
  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "foot-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    "featuredIn":(resolve) => require(["./FeaturedIn.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
	.arcanitePage{
    width: 100vw;
		// height:100%;
    display: inline-block;
    text-align: center;
    font-family: Poppins;
    // min-width: 1210px;
		overflow-x:hidden;

	}
.arcanite{
  width: 100vw;
  // height:100%;
  display: inline-block;
  text-align: center;
  position: relative;
  font-family: Poppins;
  overflow-x:hidden;
	z-index: 6;
}

// .homepage{
//   margin: auto;
//   width:1210px;
//   display: block;
//   position: relative;
//   // z-index: -999;
// }
.part1BookDemo{
  height: 100%;
  margin: 0 0 3px;
  padding: 0 0 13px;
  // background-color: white;
  position: relative;
  // z-index: 100;
  .part1Middle{
    width: 1210px;
    margin: auto;
    justify-content: space-around;
    position: relative;
  .p1{
    height: 533.5px;
    position:absolute;
    top:140px;
    left:40px;
    // z-index: 1;
  }
  .p11{
    width: 237px;
    height: 421px;
    position:absolute;
    bottom:0;
    right:0;
    // z-index: 2;
  }
  .p12{
    width: 22px;
    height: 22px;
    flex-grow: 0;
  }
  .dogs{
    width: 65px;
    height: 50px;
  }
  .p13{
    position:absolute;
    bottom:110px;
    left:-30px;
    // z-index: 0;
  }
  .p14{
    position:absolute;
    top:-55px;
    right:40px;
    // z-index: 0;
  }
  .leftContent{
    width: 520px;
    height: 466px;
    padding-top: 188px;
    padding-left: 50px;
    // z-index: 200;
  }
  .rightContent{
    width:646.8px;
    height:100%;
    position:absolute;
    bottom:0;
    right:20px;
    // z-index: -1;
  }
  .part1title{
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 56px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    margin-bottom: 30px;
  }

  .part1content{
    width: 500px;
    height: 180px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
  }

  .part1Button{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 175px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
  }
  .part1Button:hover{
    background-color: #096dd9;
  }
  .part1Button:active{
    background-color: #0757ae;
  }
  .bottomText{
    width: 111px;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    white-space: nowrap;
  }
  // .reconTrused{
  //   margin-left: 20px;
  // }
  .title{
    margin:0 0 20px 0;
    width: 84px;
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #67788c;
  }
  .picsL{
      width: 352px;
      height: 95px;
      display: flex;
      margin: 0 0 20px 0;
      .p21{
        width: 57px;
        height: 75px;
        margin: 10px;
        margin-right:40px;
      }
      .p22{
        width: 58px;
        height: 75px;
        margin: 10px;
        margin-right:40px;
      }
      .p23{
        width: 137px;
        height: 75px;
        margin: 10px;
      }
    }
    .picsR{
      width:1284px;
      display: flex;
      .pic{
        width: 100px;
        height: 40px;
        margin: 0 60px 0 0;
      }
    }
  }
}

.part2RecoginizedIcons{
  margin: 70px auto;
  // margin-top: 70px;
  width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  // padding-left: 20px;
  gap: 40px;
  .title{
    margin: 10px;
    width: 84px;
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #67788c;
  }
}

.part3PicsText{
  width: 1210px;
  margin:auto;
  margin-top: 101px;
  display: flex;
  position: relative;
  gap: 15px;
  // overflow: hidden;
  z-index: -99;
  .background{
    width: 2340px;
    height: 1122px;
    position: absolute;
    top:-250px;
    left:-565px
  }
  .leftContent{
    position:relative;
    display: inline-block;
    width: 680px;
    height: 597px;
    .p21{
      width: 744px;
      height: 724px;
      position: absolute;
      top: 0;
      left:0;
    }
  }
  .rightContent{
    position:relative;
    display: inline-block;
    width: 500px;
    // margin-left: 15px;
    height: 597px;
    }
    .p1{
      margin: 10px;
      position:absolute;
      top: 40%;
      text-align: left;
      .pic1{
        width: 80px;
        height: 80px;
      }
      p{
        margin-left: 10px;
        position:relative;
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
      }
      .content{
        margin-left: 10px;
        // width: 454px;
        position:relative;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        margin-top:10px;
      }
      .line{
        margin: 20px 0 20px 10px;
        width: 463px;
        height: 1px;
        background-color: #e6ebf2;
      }
      .dog{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ff8d5a;
        display: inline-block;
      }
      .text{
        // width:434px;;
        // height: 60px;
        margin: 10px 10px 10px 10px;
        display: inline-block;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #314455;
        word-break: break-all;
      }
      .content{
        text-align: left;
      }
      .minipeople{
        margin-top: 20px;
        display: flex;
        .minip{
          width: 92px;
          height: 92px;
          margin-right: 10px;
        }
      }
  }
}
.pic2{
  width: 744px;
  height: 724px;
  position: absolute;
  left: 0;
}
.pic3{
  width: 661px;
  height: 762px;
  position: absolute;
  right: -20px;
}
.pic4{
  width: 641px;
  height: 569px;
  position: absolute;
  left: 0;
}

.part4Feature{
  margin: auto;
  width:1210px;
  height:353px;
  position: relative;
  z-index: 0;
  .pic4{
    z-index: -99;
    width: 2560px;
    height: 354px;
    position: absolute;
    left: -675px;
  }
  h2{
      margin: auto;
      // padding-left:294px;
      padding-top: 57px;
      width: 852px;
      height: 168px;
      flex-grow: 0;
      font-family: Poppins-Bold;
      font-size: 36px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .part4Button{
        width: 175px;
        height: 42px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        border-radius: 5px;
        background-color: #062440;
		margin-left: 179px;
		margin-top: 30px;
		margin-bottom: 57px;
        // position: absolute;
        // left: 179px;
        // top:275px;
        z-index: 60;
    }
    .part4Button:hover{
      background-color: #041729;
    }
    .part4Button:active{
      background-color: #020d17;
    }
    .bottomText{
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        white-space: nowrap
    }
    .p12{
        width: 22px;
        height: 22px;
        flex-grow: 0;
        object-fit: contain;
    }
}
.part5Comments{
  margin: auto;
  width: 1210px;
  height: 883px;
  text-align: center;
  align-items: center;
  justify-content: center;

}
.pic{
    width: 91px;
    height: 91px;
}

</style>