var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('top-nav'),_c('div',{staticClass:"arcanite"},[_vm._m(0),_c('div',{staticClass:"part2RecoginizedIcons"},[_c('div',{staticClass:"part2-topcontent"},[_c('trusted')],1),_c('div',{staticClass:"part2-midcontent"},[_c('featuredIn')],1),_c('div',{staticClass:"part2-bottomcontent"},[_c('recognised')],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"part5Comments"},[_c('other-product')],1)]),_c('foot-nav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1BookDemo"},[_c('div',{staticClass:"part1Middle"},[_c('div',{staticClass:"leftContent"},[_c('div',{staticClass:"part1title"},[_vm._v(" Arcanite ")]),_c('div',{staticClass:"part1content"},[_vm._v(" Arcanite's frontend app is the must-have automation tool for on-the-go agents and developers. Stay connected, updated and on track by selling off-the-plan properties the Arcanite way! ")]),_c('a',{attrs:{"onclick":"Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');\n            return false;"}},[_c('button',{staticClass:"part1Button"},[_c('div',{staticClass:"bottomText"},[_vm._v("Book A Demo")]),_c('img',{staticClass:"p12",attrs:{"src":require("./../../assets/imgs/pc/homepage/p12Vector.png")}})])])]),_c('div',{staticClass:"rightContent"},[_c('img',{staticClass:"p1",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p1.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"700px"}},[_c('img',{staticClass:"background",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p2.png")}}),_c('div',{staticClass:"leftContent",staticStyle:{"marginTop":"80px"}},[_c('img',{staticClass:"pic2",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p21.png")}})]),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1",staticStyle:{"top":"220px"}},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minicons1.png")}}),_c('p',[_vm._v("View your projects your way")]),_c('div',{staticClass:"content"},[_vm._v(" View all your current projects with quick links to availability, specifications, sharable information, marketing material and VR property rendering. Customise how you view your home screen for efficient property searches. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"700px"}},[_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1"},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minicons2.png")}}),_c('p',[_vm._v("All of your contacts in one place")]),_c('div',{staticClass:"content"},[_vm._v(" Instantly add and organise your contact list. Sort contacts into a range of varying categories and filter based on sales stage, client source and more. ")]),_c('div',{staticClass:"minipeople"},[_c('img',{staticClass:"minip",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minip1.png")}}),_c('img',{staticClass:"minip",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minip2.png")}}),_c('img',{staticClass:"minip",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minip3.png")}}),_c('img',{staticClass:"minip",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minip4.png")}})])])]),_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic3",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText"},[_c('div',{staticClass:"leftContent"},[_c('img',{staticClass:"pic4",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p4.png")}})]),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"p1",staticStyle:{"top":"20%"}},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minicons3.png")}}),_c('p',[_vm._v("Track sales more efficiently than ever")]),_c('div',{staticClass:"content"},[_vm._v(" Track progress on all sales and filter searches based on current sales status. Sort by price and date, for efficient tracking and follow-ups. ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Filter and sort to suit your preferences")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("Oversee agent requests")]),_c('br'),_c('div',{staticClass:"dog"}),_c('div',{staticClass:"text"},[_vm._v("View sales statistics for ongoing R&D")]),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part3PicsText",staticStyle:{"height":"400px"}},[_c('div',{staticClass:"rightContent",staticStyle:{"height":"0"}},[_c('div',{staticClass:"p1"},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minicons4.png")}}),_c('p',[_vm._v("Never miss an update")]),_c('div',{staticClass:"content"},[_vm._v(" Organise and add tasks to share amongst your team. Categorise each task for quick identification and use the inbuilt calendar to ensure all responsibilities are kept on track and send out reminder notifications. ")])])]),_c('div',{staticClass:"rightContent",staticStyle:{"height":"0","margin-left":"250px"}},[_c('div',{staticClass:"p1"},[_c('img',{staticClass:"pic",attrs:{"src":require("./../../assets/imgs/pc/arcanite/minicons5.png")}}),_c('p',[_vm._v("All access to everything that matters")]),_c('div',{staticClass:"content"},[_vm._v(" Access saved lists, reports, invoices, partner contacts, training material, company information and much more. All you need to know to make a sale is in the palm of your hand. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part4Feature"},[_c('img',{staticClass:"pic4",attrs:{"src":require("./../../assets/imgs/pc/arcanite/p5.png")}}),_c('h2',[_vm._v(" Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes. ")]),_c('a',{attrs:{"onclick":"Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');\n    return false;"}},[_c('button',{staticClass:"part4Button"},[_c('div',{staticClass:"bottomText"},[_vm._v("Book A Demo")]),_c('img',{staticClass:"p12",attrs:{"src":require("./../../assets/imgs/pc/homepage/p12Vector.png")}})])])])
}]

export { render, staticRenderFns }